import { Skeleton, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useParams } from 'react-router-dom';
import { useGetCommonInnateAbilityByIdQuery } from './commonInnateAbilitiesApi';
import commonInnateAbilityItem from '../../common/components/CommonInnateAbilityItem';

function CommonInnateAbility() {
    const { id } = useParams() as { id: string };
    const { data: commonInnateAbility, isLoading } =
        useGetCommonInnateAbilityByIdQuery(id);

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    if (!commonInnateAbility) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid xs={12}>Common Innate Ability not Found</Grid>
                </Grid>
            </Box>
        );
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {commonInnateAbilityItem(commonInnateAbility)}
            </Grid>
        </Box>
    );
}

export default CommonInnateAbility;
