/* eslint-disable react/jsx-props-no-spreading */
import {
    Skeleton,
    Box,
    Stack,
    Divider,
    Paper,
    styled,
    Tabs,
    Tab,
} from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useParams, Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { useGetMonsterByNameWithFaqsQuery } from './monstersApi';
import faqItem from '../../common/components/FaqItem';

interface TabPanelProps {
    // eslint-disable-next-line react/require-default-props
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
}));

function Monster() {
    const { monsterUrlName } = useParams() as { monsterUrlName: string };
    const { data: monster, isLoading } =
        useGetMonsterByNameWithFaqsQuery(monsterUrlName);
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    if (!monster) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid xs={12}>Monster not Found</Grid>
                </Grid>
            </Box>
        );
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Card" {...a11yProps(0)} />
                    <Tab label="Rules (Text)" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
                {monster.cardImages.map((cardImage) => {
                    return (
                        <img
                            key={cardImage}
                            src={`/images/cards/${cardImage}`}
                            alt={monster.name}
                            style={{
                                width: '100%',
                                maxWidth: '413px',
                                height: 'auto',
                            }}
                        />
                    );
                })}
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                <img
                    src={`/images/${monster.avatarUrl}`}
                    alt={monster.name}
                    width="220"
                    height="220"
                />
                <Typography variant="h1">{monster.name}</Typography>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={{ xs: 0, sm: 2 }}
                >
                    <Item elevation={0}>MOV: {monster.mov}</Item>
                    <Item elevation={0}>MEL: {monster.mel}</Item>
                    <Item elevation={0}>RNG: {monster.rng}</Item>
                    <Item elevation={0}>MAG: {monster.mag}</Item>
                    <Item elevation={0}>AGI: {monster.agi}</Item>
                    <Item elevation={0}>RES: {monster.res}</Item>
                </Stack>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                >
                    <Item elevation={0}>Health: {monster.health}</Item>
                    <Item elevation={0}>Tier: {monster.tier}</Item>
                    <Item elevation={0}>Bounty: {monster.bounty}</Item>
                </Stack>
                <h3>Weapons</h3>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={{ xs: 0, sm: 2 }}
                >
                    <Item elevation={0} sx={{ width: 150 }}>
                        &nbsp;
                    </Item>
                    <Item elevation={0} sx={{ width: 60 }}>
                        <b>Type</b>
                    </Item>
                    <Item elevation={0} sx={{ width: 50 }}>
                        <b>Cost</b>
                    </Item>
                    <Item elevation={0} sx={{ width: 50 }}>
                        <b>Reach</b>
                    </Item>
                    <Item elevation={0} sx={{ width: 50 }}>
                        <b>Glance</b>
                    </Item>
                    <Item elevation={0} sx={{ width: 50 }}>
                        <b>Solid</b>
                    </Item>
                    <Item elevation={0} sx={{ width: 50 }}>
                        <b>Crit</b>
                    </Item>
                </Stack>
                {monster.weapons.map((weapon) => {
                    return (
                        <Stack
                            direction="row"
                            divider={
                                <Divider orientation="vertical" flexItem />
                            }
                            spacing={{ xs: 0, sm: 2 }}
                        >
                            <Item elevation={0} sx={{ width: 150 }}>
                                {weapon.name}
                            </Item>
                            <Item elevation={0} sx={{ width: 60 }}>
                                {weapon.type}
                            </Item>
                            <Item elevation={0} sx={{ width: 50 }}>
                                {weapon.cost}
                            </Item>
                            <Item elevation={0} sx={{ width: 50 }}>
                                {weapon.reach}
                            </Item>
                            <Item elevation={0} sx={{ width: 50 }}>
                                {weapon.glance}
                            </Item>
                            <Item elevation={0} sx={{ width: 50 }}>
                                {weapon.solid}
                            </Item>
                            <Item elevation={0} sx={{ width: 50 }}>
                                {weapon.crit}
                            </Item>
                        </Stack>
                    );
                })}
                {monster.commonInnateAbilities.length === 0 ? (
                    ''
                ) : (
                    <h3>Common Innate Abilities</h3>
                )}
                <Stack direction="row" spacing={2}>
                    {monster.commonInnateAbilities.map((ability) => {
                        return (
                            <Item elevation={0}>
                                <Link
                                    component={RouterLink}
                                    to={`/commoninnateabilities/${ability.id}`}
                                    underline="hover"
                                    color="secondary"
                                >
                                    {ability.name}
                                </Link>
                            </Item>
                        );
                    })}
                </Stack>
                {monster.uniqueInnateAbilities.length === 0 ? (
                    ''
                ) : (
                    <h3>Unique Innate Abilities</h3>
                )}
                {monster.uniqueInnateAbilities.map((ability) => {
                    return (
                        <Stack
                            direction="row"
                            divider={
                                <Divider orientation="vertical" flexItem />
                            }
                            spacing={2}
                        >
                            <Item elevation={0} sx={{ width: 150 }}>
                                {ability.name}
                            </Item>
                            <Item elevation={0}>{ability.description}</Item>
                        </Stack>
                    );
                })}
                {monster.combatManoeuvres.length === 0 ? (
                    ''
                ) : (
                    <h3>Combat Manoeuvres</h3>
                )}
                {monster.combatManoeuvres.map((combatManoeuvre) => {
                    return (
                        <Stack
                            direction="row"
                            divider={
                                <Divider orientation="vertical" flexItem />
                            }
                            spacing={2}
                        >
                            <Item elevation={0} sx={{ width: 150 }}>
                                {combatManoeuvre.name} ({combatManoeuvre.cost})
                            </Item>
                            <Item elevation={0}>
                                {combatManoeuvre.description}
                            </Item>
                        </Stack>
                    );
                })}
            </CustomTabPanel>
            <br />
            {monster.faqs.length === 0 ? (
                ''
            ) : (
                <Grid container spacing={2}>
                    <Typography variant="h1">{monster.name} FAQs</Typography>
                    {monster.faqs.map((faq) => {
                        return faqItem(faq);
                    })}
                </Grid>
            )}
        </Box>
    );
}

export default Monster;
