import { Skeleton, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useParams } from 'react-router-dom';
import { useGetGodByNameWithFaqsQuery } from './godsApi';
import artefactItem from '../../common/components/ArtefactItem';
import faqItem from '../../common/components/FaqItem';
import HeroCard from '../heroes/HeroCard';

function God() {
    const { godUrlName } = useParams() as { godUrlName: string };
    const { data: god, isLoading } = useGetGodByNameWithFaqsQuery(godUrlName);

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    if (!god) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid xs={12}>God not Found</Grid>
                </Grid>
            </Box>
        );
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <div>
                        <img
                            src={`/images/${god.logo}`}
                            alt={god.logo}
                            width="220"
                            height="220"
                        />
                        <Typography variant="h1">{god.name}</Typography>
                        <div>
                            <i>{god.traits}</i>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <div>
                        <p>{god.description}</p>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <h2>Avatars</h2>
                </Grid>
                {god.avatars.map((avatar) => {
                    return (
                        <Grid key={avatar.name} xs={12} sm={4} md={3} lg={2}>
                            <HeroCard hero={avatar} />
                        </Grid>
                    );
                })}
                <Grid xs={12}>
                    <h2>Champions</h2>
                </Grid>
                {god.champions.map((champion) => {
                    return (
                        <Grid key={champion.name} xs={12} sm={4} md={3} lg={2}>
                            <HeroCard hero={champion} />
                        </Grid>
                    );
                })}
                <Grid xs={12}>
                    <h2>Divine Gifts</h2>
                    <h3>Warband Bonus</h3>
                    <div>{god.divineGifts.warband_bonus}</div>
                    <h3>Effigy Power - {god.divineGifts.effigy_power.name}</h3>
                    <div>{god.divineGifts.effigy_power.description}</div>
                    <h4>Avatar Bonus</h4>
                    <div>{god.divineGifts.effigy_power.avatar_bonus}</div>
                    <h3>Sacred Artefact</h3>
                    {artefactItem(god.sacredArtefact)}
                </Grid>
            </Grid>
            <br />
            {god.faqs.length === 0 ? (
                ''
            ) : (
                <Grid container spacing={2}>
                    <Typography variant="h1">{god.name} FAQs</Typography>
                    {god.faqs.map((faq) => {
                        return faqItem(faq);
                    })}
                </Grid>
            )}
        </Box>
    );
}

export default God;
