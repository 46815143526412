import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    CardActions,
    Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Artefact from './interfaces/Artefact';
import '../../main.css';

interface ArtefactCardProps {
    artefact: Artefact;
}

function ArtefactCard({ artefact }: ArtefactCardProps) {
    return (
        <Card
            sx={{
                maxWidth: 230,
                margin: '0 auto',
                padding: '0.1em',
            }}
            className="darken-on-hover"
        >
            <RouterLink to={`/artefacts/${artefact.urlName}`}>
                <CardMedia
                    component="img"
                    image={`/images/${artefact.avatarUrl}`}
                    alt={artefact.avatarUrl}
                    sx={{
                        width: 220,
                        height: 220,
                    }}
                />
            </RouterLink>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {artefact.name}
                </Typography>
                <Typography paragraph>{artefact.type}</Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    color="primary"
                    component={RouterLink}
                    to={`/artefacts/${artefact.urlName}`}
                >
                    View Rules
                </Button>
            </CardActions>
        </Card>
    );
}

export default ArtefactCard;
