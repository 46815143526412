import { useAuth } from '@clerk/clerk-react';
import { useEffect, useRef } from 'react';

// Generic type for any RTK Query hook result
type QueryHookResult = {
    data?: any;
    error?: any;
    isLoading: boolean;
    refetch: () => void;
};

export default function useAuthQuery<T extends QueryHookResult>(hook: T): T {
    const { getToken } = useAuth();
    const prevToken = useRef<string | null>(null);

    useEffect(() => {
        const addAuthHeader = async () => {
            const token = await getToken();
            if (token && token !== prevToken.current) {
                localStorage.setItem('clerk-auth-token', token);
                prevToken.current = token;
                hook.refetch();
            }
        };
        addAuthHeader();
    }, [getToken, hook]);

    return hook;
}
