import judgementApi from '../../common/judgementApi';
import Game from './interfaces/Game';

const gamesApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getGames: build.query<Game[], void>({
            query: () => 'games',
        }),

        getGameById: build.query<Game, string>({
            query: (id) => `games/${id}`,
        }),

        getGamesByUser: build.query<Game[], string>({
            query: (userId) => `games/user/${userId}`,
        }),

        submitGame: build.mutation<Game, Partial<Game>>({
            query: (game) => ({
                url: 'games',
                method: 'POST',
                body: game,
            }),
        }),

        updateGame: build.mutation<Game, Partial<Game>>({
            query: (game) => ({
                url: 'games',
                method: 'PUT',
                body: game,
            }),
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
    useGetGamesQuery,
    useGetGameByIdQuery,
    useGetGamesByUserQuery,
    useSubmitGameMutation,
    useUpdateGameMutation,
} = gamesApi;
