import { Box, Skeleton } from '@mui/material';
import { useGetGamesQuery } from './gamesApi';
import GamesResultsTable from '../../common/components/GamesResultsTable';

function Games() {
    const { data: games, isLoading } = useGetGamesQuery();

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <GamesResultsTable games={games} />
        </Box>
    );
}

export default Games;
