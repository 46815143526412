import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import God from './interfaces/God';
import '../../main.css';

interface GodCardProps {
    god: God;
}

function GodCard({ god }: GodCardProps) {
    return (
        <Card sx={{ maxWidth: 400 }} className="darken-on-hover">
            <RouterLink to={`/gods/${god.urlName}`}>
                <CardMedia
                    component="img"
                    image={`/images/${god.logo}`}
                    alt={god.logo}
                    sx={{
                        width: 220,
                        height: 220,
                    }}
                />
            </RouterLink>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {god.name}
                </Typography>
                <Typography paragraph>{god.traits}</Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ whiteSpace: 'pre-line' }}
                >
                    {god.description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    color="secondary"
                    component={RouterLink}
                    to={`/gods/${god.urlName}`}
                >
                    View Rules
                </Button>
            </CardActions>
        </Card>
    );
}

export default GodCard;
