import judgementApi from '../../common/judgementApi';
import User from './interface/User';

const usersApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getUsernames: build.query<User[], void>({
            query: () => 'usernames',
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetUsernamesQuery } = usersApi;
