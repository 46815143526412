import {
    AppBar,
    Button,
    Container,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Menu,
    MenuItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';
import {
    SignedIn,
    SignedOut,
    SignInButton,
    UserButton,
} from '@clerk/clerk-react';

interface NavItem {
    name: string;
    url: string;
}
const navItems: NavItem[] = [
    { name: 'Home', url: '/' },
    { name: 'Gods', url: '/gods' },
    { name: 'Heroes', url: '/heroes' },
    { name: 'Monsters', url: '/monsters' },
    { name: 'Summons', url: '/summons' },
    { name: 'Artefacts', url: '/artefacts' },
];

const gameRulesNavItems: NavItem[] = [
    { name: 'Rulebook', url: '/pages/rulebook' },
    { name: 'Common Innate Abilities', url: '/commoninnateabilities' },
    { name: 'Conditions', url: '/pages/conditions' },
    { name: 'FAQs', url: '/faqs' },
    { name: 'Game Definitions', url: '/gamedefinitions' },
    { name: 'Patch Notes', url: '/pages/patchnotes' },
    { name: 'Terrain', url: '/pages/terrain' },
    { name: 'Warband Draft Rules', url: '/pages/warbanddraftrules' },
];

const rankingsNavItems: NavItem[] = [
    { name: 'Player Rankings 3s', url: '/playerrankings/3v3' },
    { name: 'Player Rankings 5s', url: '/playerrankings/5v5' },
    { name: 'Tournaments', url: '/tournamentresults/tournaments' },
    { name: 'Rankings Explained', url: '/pages/rankings' },
    { name: 'Tournament Seasons', url: '/pages/tournamentseasons' },
];

const resourcesNavItems: NavItem[] = [
    { name: 'Current Map Pool', url: '/currentmappool' },
    { name: 'Multi-Player Rules', url: '/pages/multiplayerrules' },
    { name: 'Vassal Module', url: '/pages/vassalmodule' },
    { name: 'Starter Box Maps', url: '/starterMaps' },
];

const gameResultsNavItems: NavItem[] = [
    { name: 'Submit Game Result', url: '/games/editgame' },
    { name: 'View Game Results', url: '/games' },
    { name: 'My Game Diary', url: '/games/diary' },
];

function NavBar() {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [anchorElRankings, setAnchorElRankings] =
        React.useState<null | HTMLElement>(null);
    const [anchorElResources, setAnchorElResources] =
        React.useState<null | HTMLElement>(null);
    const [anchorElGameRules, setAnchorElGameRules] =
        React.useState<null | HTMLElement>(null);
    const [anchorElGameResults, setAnchorElGameResults] =
        React.useState<null | HTMLElement>(null);
    const openRankings = Boolean(anchorElRankings);
    const openResources = Boolean(anchorElResources);
    const openGameRules = Boolean(anchorElGameRules);
    const openGameResults = Boolean(anchorElGameResults);

    const handleClickRankings = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorElRankings(event.currentTarget);
    };
    const handleCloseRankings = () => {
        setAnchorElRankings(null);
    };
    const handleClickResources = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorElResources(event.currentTarget);
    };
    const handleCloseResources = () => {
        setAnchorElResources(null);
    };
    const handleClickGameRules = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorElGameRules(event.currentTarget);
    };
    const handleCloseGameRules = () => {
        setAnchorElGameRules(null);
    };
    const handleClickGameResults = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorElGameResults(event.currentTarget);
    };
    const handleCloseGameResults = () => {
        setAnchorElGameResults(null);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Judgement: Hall of Eternal Champions
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton
                            sx={{ textAlign: 'center' }}
                            component={Link}
                            to={item.url}
                            key={item.name}
                        >
                            <ListItemText primary={item.name} key={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <Divider />
                {rankingsNavItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton
                            sx={{ textAlign: 'center' }}
                            component={Link}
                            to={item.url}
                            key={item.name}
                        >
                            <ListItemText primary={item.name} key={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <Divider />
                {resourcesNavItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton
                            sx={{ textAlign: 'center' }}
                            component={Link}
                            to={item.url}
                            key={item.name}
                        >
                            <ListItemText primary={item.name} key={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <Divider />
                {gameRulesNavItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton
                            sx={{ textAlign: 'center' }}
                            component={Link}
                            to={item.url}
                            key={item.name}
                        >
                            <ListItemText primary={item.name} key={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <Divider />
                {gameResultsNavItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton
                            sx={{ textAlign: 'center' }}
                            component={Link}
                            to={item.url}
                            key={item.name}
                        >
                            <ListItemText primary={item.name} key={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <SignedOut>
                <SignInButton mode="modal">
                    <Button color="primary" variant="contained">
                        Sign In
                    </Button>
                </SignInButton>
            </SignedOut>
            <SignedIn>
                <UserButton afterSignOutUrl="/" />
            </SignedIn>
        </Box>
    );

    return (
        <>
            <AppBar component="nav" position="sticky">
                <Container maxWidth="xl">
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'flex', md: 'none' },
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'flex' },
                            }}
                        >
                            <img
                                src="/images/jec-logo-gradient.png"
                                alt="Judgement: Eternal Champions"
                                width="60"
                                height="60"
                            />
                        </Typography>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'none', md: 'flex' },
                            }}
                        >
                            {navItems.map((item) => (
                                <Button
                                    key={item.name}
                                    color="secondary"
                                    component={Link}
                                    to={item.url}
                                >
                                    {item.name}
                                </Button>
                            ))}
                            <Button
                                id="rankings-button"
                                aria-controls={
                                    openRankings ? 'basic-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                    openRankings ? 'true' : undefined
                                }
                                onClick={handleClickRankings}
                            >
                                Rankings
                            </Button>
                            <Button
                                id="resources-button"
                                aria-controls={
                                    openResources ? 'basic-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                    openResources ? 'true' : undefined
                                }
                                onClick={handleClickResources}
                            >
                                Resources
                            </Button>
                            <Button
                                id="game-rules-button"
                                aria-controls={
                                    openGameRules ? 'basic-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                    openGameRules ? 'true' : undefined
                                }
                                onClick={handleClickGameRules}
                            >
                                Game Rules
                            </Button>
                            <Button
                                id="statistics-button"
                                aria-controls={
                                    openGameResults ? 'basic-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                    openGameResults ? 'true' : undefined
                                }
                                onClick={handleClickGameResults}
                            >
                                Game Results
                            </Button>
                            <Menu
                                id="rankings-menu"
                                anchorEl={anchorElRankings}
                                open={openRankings}
                                onClose={handleCloseRankings}
                                MenuListProps={{
                                    'aria-labelledby': 'rankings-button',
                                }}
                            >
                                {rankingsNavItems.map((item) => (
                                    <MenuItem onClick={handleCloseRankings}>
                                        <Button
                                            key={item.name}
                                            color="secondary"
                                            component={Link}
                                            to={item.url}
                                        >
                                            {item.name}
                                        </Button>
                                    </MenuItem>
                                ))}
                            </Menu>
                            <Menu
                                id="resources-menu"
                                anchorEl={anchorElResources}
                                open={openResources}
                                onClose={handleCloseResources}
                                MenuListProps={{
                                    'aria-labelledby': 'resources-button',
                                }}
                            >
                                {resourcesNavItems.map((item) => (
                                    <MenuItem onClick={handleCloseResources}>
                                        <Button
                                            key={item.name}
                                            color="secondary"
                                            component={Link}
                                            to={item.url}
                                        >
                                            {item.name}
                                        </Button>
                                    </MenuItem>
                                ))}
                            </Menu>
                            <Menu
                                id="game-rules-menu"
                                anchorEl={anchorElGameRules}
                                open={openGameRules}
                                onClose={handleCloseGameRules}
                                MenuListProps={{
                                    'aria-labelledby': 'game-rules-button',
                                }}
                            >
                                {gameRulesNavItems.map((item) => (
                                    <MenuItem onClick={handleCloseGameRules}>
                                        <Button
                                            key={item.name}
                                            color="secondary"
                                            component={Link}
                                            to={item.url}
                                        >
                                            {item.name}
                                        </Button>
                                    </MenuItem>
                                ))}
                            </Menu>
                            <Menu
                                id="game-results-menu"
                                anchorEl={anchorElGameResults}
                                open={openGameResults}
                                onClose={handleCloseGameResults}
                                MenuListProps={{
                                    'aria-labelledby': 'game-results-button',
                                }}
                            >
                                {gameResultsNavItems.map((item) => (
                                    <MenuItem onClick={handleCloseGameResults}>
                                        <Button
                                            key={item.name}
                                            color="secondary"
                                            component={Link}
                                            to={item.url}
                                        >
                                            {item.name}
                                        </Button>
                                    </MenuItem>
                                ))}
                            </Menu>
                            <SignedOut>
                                <SignInButton mode="modal">
                                    <Button color="primary" variant="contained">
                                        Sign In
                                    </Button>
                                </SignInButton>
                            </SignedOut>
                            <SignedIn>
                                <UserButton afterSignOutUrl="/" />
                            </SignedIn>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: 240,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </>
    );
}

export default NavBar;
