import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Monster from './interfaces/Monster';
import '../../main.css';

interface MonsterCardProps {
    monster: Monster;
}

function MonsterCard({ monster }: MonsterCardProps) {
    return (
        <Card
            sx={{
                maxWidth: 230,
                margin: '0 auto',
                padding: '0.1em',
            }}
            className="darken-on-hover"
        >
            <RouterLink to={`/monsters/${monster.urlName}`}>
                <CardMedia
                    component="img"
                    image={`/images/${monster.avatarUrl}`}
                    alt={monster.name}
                    sx={{
                        width: 220,
                        height: 220,
                        padding: 2,
                    }}
                />
            </RouterLink>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {monster.name}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ whiteSpace: 'pre-line' }}
                >
                    {`${monster.race}`}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    color="primary"
                    component={RouterLink}
                    to={`/monsters/${monster.urlName}`}
                >
                    View Rules
                </Button>
            </CardActions>
        </Card>
    );
}

export default MonsterCard;
