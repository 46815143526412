import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Artefact from '../../features/artefacts/interfaces/Artefact';
import faqItem from './FaqItem';
import Faq from '../../features/faqs/interfaces/Faq';

function ArtefactItem({
    name,
    avatarUrl,
    type,
    cost,
    description,
    category,
    quote,
    faqs = [],
}: Artefact) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <div>
                        <img
                            src={`/images/${avatarUrl}`}
                            alt={avatarUrl}
                            width="220"
                            height="220"
                        />
                        <Typography variant="h1">{name}</Typography>
                        <div>
                            <i>{type}</i>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <div>
                        <b>Cost:</b>&nbsp;{cost}
                    </div>
                </Grid>
                <Grid xs={12}>
                    <div>
                        <b>Category:</b>&nbsp;{category}
                    </div>
                </Grid>
                <Grid xs={12}>
                    <div>
                        <p>{description}</p>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <div>
                        <i>{quote}</i>
                    </div>
                </Grid>
            </Grid>
            <br />
            {faqs.length === 0 ? (
                ''
            ) : (
                <Grid container spacing={2}>
                    <Typography variant="h1">{name} FAQs</Typography>
                    {faqs.map((faq: Faq) => {
                        return faqItem(faq);
                    })}
                </Grid>
            )}
        </Box>
    );
}

export default ArtefactItem;
