import judgementApi from '../../common/judgementApi';
import CommonInnateAbility from './interfaces/CommonInnateAbility';

const commonInnateAbilitiesApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getCommonInnateAbilities: build.query<CommonInnateAbility[], void>({
            query: () => 'commonInnateAbilities',
        }),
        getCommonInnateAbilityByName: build.query<CommonInnateAbility, string>({
            query: (name) => `commonInnateAbilities/${name}`,
        }),
        getCommonInnateAbilityById: build.query<CommonInnateAbility, string>({
            query: (id) => `commonInnateAbilities/id/${id}`,
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
    useGetCommonInnateAbilitiesQuery,
    useGetCommonInnateAbilityByNameQuery,
    useGetCommonInnateAbilityByIdQuery,
} = commonInnateAbilitiesApi;
