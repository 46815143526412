import React from 'react';
import { SignedIn, SignedOut, SignInButton, useUser } from '@clerk/clerk-react';
import { Box, Button } from '@mui/material';
import { useGetGamesByUserQuery } from './gamesApi';
import GamesResultsTable from '../../common/components/GamesResultsTable';
import useAuthQuery from '../../common/useAuthQuery';

function GameDiary(): React.ReactElement {
    const { user } = useUser();
    const { data: games } = useAuthQuery(
        useGetGamesByUserQuery(user?.username ?? '')
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <SignedOut>
                <SignInButton afterSignInUrl="/games/editgame" mode="modal">
                    <Button color="primary" variant="contained">
                        Sign In
                    </Button>
                </SignInButton>
                <p>You must be signed in to view your game diary.</p>
            </SignedOut>
            <SignedIn>
                <div>
                    {user && (
                        <h2>
                            {user.firstName} {user.lastName} - Game Diary
                        </h2>
                    )}
                </div>
                <GamesResultsTable games={games} />
            </SignedIn>
        </Box>
    );
}

export default GameDiary;
