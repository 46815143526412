import { Button } from '@mui/material';
import { GridColDef, DataGrid, GridKeyValue } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import Game from '../../features/games/interfaces/Game';

interface GamesResultsTableProps {
    games: Game[] | undefined;
}

function GamesResultsTable({
    games,
}: GamesResultsTableProps): React.ReactElement {
    const { user } = useUser();
    const loggedInUser = user?.username || '';
    const navigate = useNavigate();

    interface ButtonClickEvent extends React.MouseEvent<HTMLButtonElement> {}

    interface RowData {
        id: GridKeyValue;
        [key: string]: any;
    }

    const onButtonClick = (e: ButtonClickEvent, row: RowData) => {
        e.stopPropagation();
        navigate(`/games/editgame/${row.id}`);
    };

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
        },
        {
            field: 'submitted_by',
            headerName: 'Submitted By',
        },
        {
            field: 'game_date',
            headerName: 'Date',
            flex: 1,
            editable: false,
            valueFormatter: (value) => {
                const date = new Date(value);
                return dayjs(date).isValid()
                    ? dayjs(date).format('DD MMM YYYY')
                    : 'Invalid Date';
            },
        },
        { field: 'format', headerName: 'Format', flex: 1, editable: false },
        {
            field: 'game_type',
            headerName: 'Type',
            flex: 1,
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <img
                        src={`/images/${params.value.toLowerCase()}-logo.jpg`}
                        alt={params.value}
                        style={{ width: '30px', height: '30px' }}
                    />
                </div>
            ),
        },
        { field: 'map', headerName: 'Map', flex: 1, editable: false },
        {
            field: 'winner.god',
            headerName: 'Winner God',
            flex: 1,
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <img
                        src={`/images/${params.row.winner.god.toLowerCase()}-logo.jpg`}
                        alt={params.row.winner.god}
                        style={{ width: '30px', height: '30px' }}
                    />
                </div>
            ),
        },
        {
            field: 'loser.god',
            headerName: 'Loser God',
            flex: 1,
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <img
                        src={`/images/${params.row.loser.god.toLowerCase()}-logo.jpg`}
                        alt={params.row.loser.god}
                        style={{ width: '30px', height: '30px' }}
                    />
                </div>
            ),
        },
        {
            field: 'winner.user_name',
            headerName: 'Winner',
            flex: 1,
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    {params.row.winner.user_name
                        ? params.row.winner.user_name
                        : 'unknown'}
                </div>
            ),
        },
        {
            field: 'loser.user_name',
            headerName: 'Loser',
            flex: 1,
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    {params.row.loser.user_name
                        ? params.row.loser.user_name
                        : 'unknown'}
                </div>
            ),
        },
        {
            field: 'actions',
            headerName: '',
            width: 80,
            renderCell: (params) => {
                return (
                    <Button
                        onClick={(e) => onButtonClick(e, params.row)}
                        variant="contained"
                        disabled={loggedInUser !== params.row.submitted_by}
                    >
                        Edit
                    </Button>
                );
            },
        },
    ];

    const handleRowClick = (params: any) => {
        navigate(`/games/${params.id}`);
    };

    return (
        // add a submit game button
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/games/editgame')}
            >
                Submit Game Result
            </Button>
            <DataGrid
                columns={columns}
                columnVisibilityModel={{
                    id: false,
                    submitted_by: false,
                }}
                rows={games ?? []}
                onRowClick={handleRowClick}
            />
        </>
    );
}

export default GamesResultsTable;
