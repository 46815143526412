import {
    Skeleton,
    FormControl,
    InputLabel,
    MenuItem,
    Box,
    Checkbox,
    FormControlLabel,
    Button,
    FormHelperText,
    Autocomplete,
    TextField,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { SignedOut, SignInButton, SignedIn, useUser } from '@clerk/clerk-react';
import { useParams } from 'react-router-dom';
import { useGetGodsQuery } from '../gods/godsApi';
import { useGetHeroesQuery } from '../heroes/heroesApi';
import {
    useGetGameByIdQuery,
    useSubmitGameMutation,
    useUpdateGameMutation,
} from './gamesApi';
import { useGetArtefactsQuery } from '../artefacts/artefactsApi';
import { useGetUsernamesQuery } from '../users/usersApi';
import useAuthQuery from '../../common/useAuthQuery';
import Winner from './interfaces/Winner';
import Loser from './interfaces/Loser';
import Hero from './interfaces/GameHeroes';

function EditGame() {
    // if we are editing an existing game, load the game
    const { gameId } = useParams() as { gameId: string };
    const { data: game, isLoading: gameIsLoading } =
        useGetGameByIdQuery(gameId);
    const { data: heroes, isLoading: heroesIsLoading } = useGetHeroesQuery();
    const { data: gods, isLoading: godsIsLoading } = useGetGodsQuery();
    const { data: artefacts, isLoading: artefactsIsLoading } =
        useGetArtefactsQuery();
    const { data: users, isLoading: usernamesIsLoading } = useAuthQuery(
        useGetUsernamesQuery()
    );
    const { user } = useUser();
    const loggedInUser = user?.username || '';

    const [selectedGameFormat, setSelectedGameFormat] = useState('Veto');
    const [selectedGameType, setSelectedGameType] = useState('Tabletop');
    const [selectedMap, setSelectedMap] = useState('PleaseSelect');
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
    // ===============
    // winner state
    // ===============
    const [winnerGod, setWinnerGod] = useState('Select God');
    const [selectedWinnerHero, setSelectedWinnerHero] =
        useState<string>('Please Select');
    const [firstTurnWinner, setFirstTurnWinner] = useState(false);
    const [winnerHeroes, setWinnerHeroes] = useState<Hero[]>([]);
    const [
        selectedWinnerHeroOffensiveArtefact,
        setWinnerHeroOffensiveArtefact,
    ] = useState('None');
    const [
        selectedWinnerHeroDefensiveArtefact,
        setWinnerHeroDefensiveArtefact,
    ] = useState('None');
    const [selectedWinnerHeroLevel, setWinnerHeroLevel] = useState(1);
    const [selectedWinnerHeroPickAndBan, setWinnerHeroPickAndBan] =
        useState<string>('Picked');
    const [winnerUsername, setWinnerUsername] = useState('');
    // ===============
    // loser state
    // ===============
    const [loserGod, setLoserGod] = useState('Select God');
    const [loserSouls, setLoserSouls] = useState<number>(0);
    const [selectedLoserHero, setSelectedLoserHero] =
        useState<string>('Please Select');
    const [firstTurnLoser, setFirstTurnLoser] = useState(false);
    const [loserHeroes, setLoserHeroes] = useState<Hero[]>([]);
    const [selectedLoserHeroOffensiveArtefact, setLoserHeroOffensiveArtefact] =
        useState('None');
    const [selectedLoserHeroDefensiveArtefact, setLoserHeroDefensiveArtefact] =
        useState('None');
    const [selectedLoserHeroLevel, setLoserHeroLevel] = useState(1);
    const [selectedLoserHeroPickAndBan, setLoserHeroPickAndBan] =
        useState<string>('Picked');
    const [loserUsername, setLoserUsername] = useState('');
    // ===============
    // snackbar state
    // ===============
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [submitGame, { isLoading: isSubmitting }] = useSubmitGameMutation();
    const [updateGame, { isLoading: isUpdating }] = useUpdateGameMutation();
    const [snackBarMessage, setSnackBarMessage] = useState('');

    useEffect(() => {
        if (game) {
            setSelectedGameFormat(game.format);
            setSelectedGameType(game.game_type);
            setSelectedMap(game.map);
            setSelectedDate(dayjs(game.game_date));
            setWinnerGod(game.winner.god);
            setFirstTurnWinner(game.winner.first_turn);
            setWinnerHeroes(game.winner.heroes);
            setWinnerUsername(game.winner.user_name);
            setLoserGod(game.loser.god);
            setLoserSouls(game.loser.souls);
            setFirstTurnLoser(game.loser.first_turn);
            setLoserHeroes(game.loser.heroes);
            setLoserUsername(game.loser.user_name);
        }
    }, [game]);

    const handleSnackbarClose = (
        event: Event | React.SyntheticEvent<any, Event>,
        reason: SnackbarCloseReason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    // ===============
    // handlers
    // ===============

    const handleGameFormatChange = (event: SelectChangeEvent) => {
        setSelectedGameFormat(event.target.value as string);
    };
    const handleGameTypeChange = (event: SelectChangeEvent) => {
        setSelectedGameType(event.target.value as string);
    };
    const handleMapChange = (event: SelectChangeEvent) => {
        setSelectedMap(event.target.value as string);
    };
    const handleDateChange = (date: Dayjs | null) => {
        setSelectedDate(date);
    };
    // ===============
    // winner handlers
    // ===============
    const handleWinnerHeroChange = (event: SelectChangeEvent) => {
        setSelectedWinnerHero(event.target.value as string);
    };
    const handleWinnerGodChange = (event: SelectChangeEvent) => {
        setWinnerGod(event.target.value as string);
    };
    const handleFirstTurnWinnerChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFirstTurnWinner(event.target.checked);
        setFirstTurnLoser(!event.target.checked);
    };
    const handleWinnerHeroOffensiveArtefactChange = (
        event: SelectChangeEvent
    ) => {
        setWinnerHeroOffensiveArtefact(event.target.value as string);
    };
    const handleWinnerHeroDefensiveArtefactChange = (
        event: SelectChangeEvent
    ) => {
        setWinnerHeroDefensiveArtefact(event.target.value as string);
    };
    const handleWinnerHeroLevelChange = (event: SelectChangeEvent<number>) => {
        setWinnerHeroLevel(Number(event.target.value));
    };
    const handleWinnerHeroPickAndBanChange = (event: SelectChangeEvent) => {
        setWinnerHeroPickAndBan(event.target.value as string);
    };
    const handleWinnerUsernameChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: string | null
    ) => {
        setWinnerUsername(value || '');
    };
    const addWinnerHero = () => {
        if (selectedWinnerHero === 'Please Select') {
            setSnackBarMessage('Please select a Hero');
            setSnackbarOpen(true);
            return;
        }
        if (winnerHeroes?.find((hero) => hero.name === selectedWinnerHero)) {
            setSnackBarMessage('Duplicate hero selected');
            setSnackbarOpen(true);
            return;
        }
        // ensure hero level is between 1 and 3
        if (selectedWinnerHeroLevel < 1 || selectedWinnerHeroLevel > 3) {
            setSnackBarMessage('Hero level must be between 1 and 3');
            setSnackbarOpen(true);
            return;
        }
        // ensure hero offensive artefact is not already in the list unless the hero is Bale and the artefact is Bale's offensive artefact
        if (
            selectedWinnerHeroOffensiveArtefact !== 'None' &&
            winnerHeroes?.find(
                (hero) =>
                    hero.offensive_artefact ===
                        selectedWinnerHeroOffensiveArtefact &&
                    hero.name !== 'Bale' &&
                    selectedWinnerHero !== 'Bale'
            )
        ) {
            setSnackBarMessage('Duplicate offensive artefact selected');
            setSnackbarOpen(true);
            return;
        }
        // ensure hero defensive artefact is not already in the list unless the hero is Bale and the artefact is Bale's defensive artefact
        if (
            selectedWinnerHeroDefensiveArtefact !== 'None' &&
            winnerHeroes?.find(
                (hero) =>
                    hero.defensive_artefact ===
                        selectedWinnerHeroDefensiveArtefact &&
                    hero.name !== 'Bale' &&
                    selectedWinnerHero !== 'Bale'
            )
        ) {
            setSnackBarMessage('Duplicate defensive artefact selected');
            setSnackbarOpen(true);
            return;
        }
        // ensure there are not 5 heroes already selected that are not banned or dropped
        if (
            winnerHeroes?.filter(
                (hero) => hero.banned === false && hero.dropped === false
            ).length >= 5 &&
            selectedWinnerHeroPickAndBan === 'Picked'
        ) {
            setSnackBarMessage('You have already selected 5 heroes');
            setSnackbarOpen(true);
            return;
        }
        // ensure there are no more than 7 heroes selected
        if (winnerHeroes?.length >= 7) {
            setSnackBarMessage('You have already selected 7 heroes');
            setSnackbarOpen(true);
            return;
        }
        // ensure if format is Veto, there is not more than 1 banned hero and 1 dropped hero
        if (
            selectedGameFormat === 'Veto' &&
            winnerHeroes?.filter((hero) => hero.banned).length >= 1 &&
            selectedWinnerHeroPickAndBan === 'Banned'
        ) {
            setSnackBarMessage('You can only have one banned hero in Veto');
            setSnackbarOpen(true);
            return;
        }
        if (
            selectedGameFormat === 'Veto' &&
            winnerHeroes?.filter((hero) => hero.dropped).length >= 1 &&
            selectedWinnerHeroPickAndBan === 'Dropped'
        ) {
            setSnackBarMessage('You can only have one dropped hero in Veto');
            setSnackbarOpen(true);
            return;
        }
        // ensure if format is Immunity, there is not more than 2 banned heroes and 0 dropped heroes
        if (
            selectedGameFormat === 'Immunity' &&
            winnerHeroes?.filter((hero) => hero.banned).length >= 2 &&
            selectedWinnerHeroPickAndBan === 'Banned'
        ) {
            setSnackBarMessage('You can only have 2 banned heroes in Immunity');
            setSnackbarOpen(true);
            return;
        }
        if (
            selectedGameFormat === 'Immunity' &&
            winnerHeroes?.filter((hero) => hero.dropped).length >= 1 &&
            selectedWinnerHeroPickAndBan === 'Dropped'
        ) {
            setSnackBarMessage('You cannot add a dropped hero in Immunity');
            setSnackbarOpen(true);
            return;
        }
        setWinnerHeroes((prevHeroes) => [
            ...prevHeroes,
            {
                name: selectedWinnerHero,
                banned: selectedWinnerHeroPickAndBan === 'Banned',
                dropped: selectedWinnerHeroPickAndBan === 'Dropped',
                offensive_artefact: selectedWinnerHeroOffensiveArtefact,
                defensive_artefact: selectedWinnerHeroDefensiveArtefact,
                avatar_url:
                    heroes?.find((hero) => hero.name === selectedWinnerHero)
                        ?.avatarUrl || '',
                level: selectedWinnerHeroLevel,
                url_name:
                    heroes?.find((hero) => hero.name === selectedWinnerHero)
                        ?.urlName || '',
            },
        ]);
        setSelectedWinnerHero('Please Select');
        setWinnerHeroOffensiveArtefact('None');
        setWinnerHeroDefensiveArtefact('None');
        setWinnerHeroPickAndBan('Picked');
        setWinnerHeroLevel(1);
    };
    const removeWinnerHero = (heroName: string) => {
        setWinnerHeroes((prevHeroes) =>
            prevHeroes.filter((hero) => hero.name !== heroName)
        );
    };
    // ===============
    // loser handlers
    // ===============
    const handleLoserHeroChange = (event: SelectChangeEvent) => {
        setSelectedLoserHero(event.target.value as string);
    };
    const handleLoserGodChange = (event: SelectChangeEvent) => {
        setLoserGod(event.target.value as string);
    };
    const handleLoserSoulsChange = (event: SelectChangeEvent<number>) => {
        setLoserSouls(Number(event.target.value));
    };
    const handleFirstTurnLoserChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFirstTurnLoser(event.target.checked);
        setFirstTurnWinner(!event.target.checked);
    };
    const handleLoserUsernameChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: string | null
    ) => {
        setLoserUsername(value || '');
    };
    const handleLoserHeroOffensiveArtefactChange = (
        event: SelectChangeEvent
    ) => {
        setLoserHeroOffensiveArtefact(event.target.value as string);
    };
    const handleLoserHeroDefensiveArtefactChange = (
        event: SelectChangeEvent
    ) => {
        setLoserHeroDefensiveArtefact(event.target.value as string);
    };
    const handleLoserHeroLevelChange = (event: SelectChangeEvent<number>) => {
        setLoserHeroLevel(Number(event.target.value));
    };
    const handleLoserHeroPickAndBanChange = (event: SelectChangeEvent) => {
        setLoserHeroPickAndBan(event.target.value as string);
    };
    const addLoserHero = () => {
        if (selectedLoserHero === 'Please Select') {
            setSnackBarMessage('Please select a Hero');
            setSnackbarOpen(true);
            return;
        }
        if (loserHeroes?.find((hero) => hero.name === selectedLoserHero)) {
            setSnackBarMessage('Duplicate hero selected');
            setSnackbarOpen(true);
            return;
        }
        // ensure hero level is between 1 and 3
        if (selectedLoserHeroLevel < 1 || selectedLoserHeroLevel > 3) {
            setSnackBarMessage('Hero level must be between 1 and 3');
            setSnackbarOpen(true);
            return;
        }
        // ensure hero offensive artefact is not already in the list unless the hero is Bale and the artefact is Bale's offensive artefact
        if (
            selectedLoserHeroOffensiveArtefact !== 'None' &&
            loserHeroes?.find(
                (hero) =>
                    hero.offensive_artefact ===
                        selectedLoserHeroOffensiveArtefact &&
                    hero.name !== 'Bale' &&
                    selectedLoserHero !== 'Bale'
            )
        ) {
            setSnackBarMessage('Duplicate offensive artefact selected');
            setSnackbarOpen(true);
            return;
        }
        // ensure hero defensive artefact is not already in the list unless the hero is Bale and the artefact is Bale's defensive artefact
        if (
            selectedLoserHeroDefensiveArtefact !== 'None' &&
            loserHeroes?.find(
                (hero) =>
                    hero.defensive_artefact ===
                        selectedLoserHeroDefensiveArtefact &&
                    hero.name !== 'Bale' &&
                    selectedLoserHero !== 'Bale'
            )
        ) {
            setSnackBarMessage('Duplicate defensive artefact selected');
            setSnackbarOpen(true);
            return;
        }
        // ensure there are not 5 heroes already selected that are not banned or dropped
        if (
            loserHeroes?.filter(
                (hero) => hero.banned === false && hero.dropped === false
            ).length >= 5 &&
            selectedLoserHeroPickAndBan === 'Picked'
        ) {
            setSnackBarMessage('You have already selected 5 heroes');
            setSnackbarOpen(true);
            return;
        }
        // ensure there are no more than 7 heroes selected
        if (loserHeroes?.length >= 7) {
            setSnackBarMessage('You have already selected 7 heroes');
            setSnackbarOpen(true);
            return;
        }
        // ensure if format is Veto, there is not more than 1 banned hero and 1 dropped hero
        if (
            selectedGameFormat === 'Veto' &&
            loserHeroes?.filter((hero) => hero.banned).length >= 1 &&
            selectedLoserHeroPickAndBan === 'Banned'
        ) {
            setSnackBarMessage('You can only have one banned hero in Veto');
            setSnackbarOpen(true);
            return;
        }
        if (
            selectedGameFormat === 'Veto' &&
            loserHeroes?.filter((hero) => hero.dropped).length >= 1 &&
            selectedLoserHeroPickAndBan === 'Dropped'
        ) {
            setSnackBarMessage('You can only have one dropped hero in Veto');
            setSnackbarOpen(true);
            return;
        }
        // ensure if format is Immunity, there is not more than 2 banned heroes and 0 dropped heroes
        if (
            selectedGameFormat === 'Immunity' &&
            loserHeroes?.filter((hero) => hero.banned).length >= 2 &&
            selectedLoserHeroPickAndBan === 'Banned'
        ) {
            setSnackBarMessage('You can only have 2 banned heroes in Immunity');
            setSnackbarOpen(true);
            return;
        }
        if (
            selectedGameFormat === 'Immunity' &&
            loserHeroes?.filter((hero) => hero.dropped).length >= 1 &&
            selectedLoserHeroPickAndBan === 'Dropped'
        ) {
            setSnackBarMessage('You cannot add a dropped hero in Immunity');
            setSnackbarOpen(true);
            return;
        }
        setLoserHeroes((prevHeroes) => [
            ...prevHeroes,
            {
                name: selectedLoserHero,
                banned: selectedLoserHeroPickAndBan === 'Banned',
                dropped: selectedLoserHeroPickAndBan === 'Dropped',
                offensive_artefact: selectedLoserHeroOffensiveArtefact,
                defensive_artefact: selectedLoserHeroDefensiveArtefact,
                avatar_url:
                    heroes?.find((hero) => hero.name === selectedLoserHero)
                        ?.avatarUrl || '',
                level: selectedLoserHeroLevel,
                url_name:
                    heroes?.find((hero) => hero.name === selectedLoserHero)
                        ?.urlName || '',
            },
        ]);
        setSelectedLoserHero('Please Select');
        setLoserHeroOffensiveArtefact('None');
        setLoserHeroDefensiveArtefact('None');
        setLoserHeroPickAndBan('Picked');
        setLoserHeroLevel(1);
    };
    const removeLoserHero = (heroName: string) => {
        setLoserHeroes((prevHeroes) =>
            prevHeroes.filter((hero) => hero.name !== heroName)
        );
    };
    // ===============
    // handle submit
    // ===============
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        let validationError: boolean = false;
        // ensure map is selected
        if (selectedMap === 'PleaseSelect') {
            setSnackBarMessage('Please select a Map');
            setSnackbarOpen(true);
            validationError = true;
        }
        // ensure date is selected
        if (selectedDate === null) {
            setSnackBarMessage('Please select a Game Date');
            setSnackbarOpen(true);
            validationError = true;
        }
        // ensure loserSouls is a number and between 0 and 4
        if (
            !loserSouls ||
            Number.isNaN(Number(loserSouls)) ||
            Number(loserSouls) > 4 ||
            Number(loserSouls) < 0
        ) {
            setSnackBarMessage('Loser Souls must be a number between 0 and 4');
            setSnackbarOpen(true);
            validationError = true;
        }
        // ensure winnerGod and loserGod are selected
        if (winnerGod === 'Select God' || loserGod === 'Select God') {
            setSnackBarMessage('Please select the winning and losing Gods');
            setSnackbarOpen(true);
            validationError = true;
        }
        // ensure first turn is selected
        if (!firstTurnWinner && !firstTurnLoser) {
            setSnackBarMessage('Please mark which player had first turn');
            setSnackbarOpen(true);
            validationError = true;
        }
        // ensure there are exactly 7 heroes in each warband
        if (winnerHeroes.length !== 7 || loserHeroes.length !== 7) {
            setSnackBarMessage(
                'There must be exactly 7 heroes in each Warband'
            );
            setSnackbarOpen(true);
            validationError = true;
        }
        // ensure winner and loser username are differnt
        if (
            winnerUsername === loserUsername &&
            winnerUsername !== '' &&
            loserUsername !== ''
        ) {
            setSnackBarMessage('Winner and Loser usernames must be different');
            setSnackbarOpen(true);
            validationError = true;
        }
        if (validationError) return;
        const winner: Winner = {
            first_turn: firstTurnWinner,
            god: winnerGod,
            heroes: winnerHeroes,
            levels: winnerHeroes
                .filter((hero) => hero.banned !== true && hero.dropped !== true)
                .reduce((sum, hero) => sum + hero.level, 0), // sum of levels of picked heroes that are not banned or dropped
            souls: 5,
            user_name: winnerUsername,
        };
        const loser: Loser = {
            first_turn: firstTurnLoser,
            god: loserGod,
            heroes: loserHeroes,
            levels: loserHeroes
                .filter((hero) => hero.banned !== true && hero.dropped !== true)
                .reduce((sum, hero) => sum + hero.level, 0), // sum of levels of all heroes that are not banned or dropped
            souls: loserSouls,
            user_name: loserUsername,
        };
        try {
            if (gameId) {
                await updateGame({
                    id: gameId,
                    size: '5v5',
                    format: selectedGameFormat,
                    game_type: selectedGameType,
                    map: selectedMap,
                    game_date: selectedDate ? selectedDate.toISOString() : '',
                    winner,
                    loser,
                    submitted_by: game?.submitted_by,
                    submitted_date: game?.submitted_date,
                    updated_by: loggedInUser,
                    updated_date: new Date().toISOString(),
                    patch: game?.patch,
                }).unwrap();
            } else {
                await submitGame({
                    size: '5v5',
                    format: selectedGameFormat,
                    game_type: selectedGameType,
                    map: selectedMap,
                    game_date: selectedDate ? selectedDate.toISOString() : '',
                    winner,
                    loser,
                    submitted_by: loggedInUser,
                }).unwrap();
            }
            setSnackBarMessage('Game submitted successfully');
            setSnackbarOpen(true);
            // reset form
            setSelectedGameFormat('Veto');
            setSelectedGameType('Tabletop');
            setSelectedMap('PleaseSelect');
            setSelectedDate(null);
            setWinnerGod('Select God');
            setFirstTurnWinner(false);
            setWinnerUsername('');
            setWinnerHeroes([]);
            setWinnerHeroOffensiveArtefact('None');
            setWinnerHeroDefensiveArtefact('None');
            setWinnerHeroLevel(1);
            setWinnerHeroPickAndBan('Picked');
            setLoserGod('Select God');
            setLoserSouls(0);
            setFirstTurnLoser(false);
            setLoserUsername('');
            setLoserHeroes([]);
            setLoserHeroOffensiveArtefact('None');
            setLoserHeroDefensiveArtefact('None');
            setLoserHeroLevel(1);
            setLoserHeroPickAndBan('Picked');
        } catch (error) {
            if ((error as { status: number }).status === 422) {
                const errorObject = error as { status: number; data: any };
                setSnackBarMessage(
                    `Game not submitted: ${errorObject.data.reason}`
                );
            } else if (error instanceof Error) {
                setSnackBarMessage(`Game not submitted: ${error.message}`);
            } else {
                setSnackBarMessage(
                    'Game not submitted: An unknown error occurred'
                );
            }
        }
        setSnackbarOpen(true);
    };

    if (
        heroesIsLoading ||
        godsIsLoading ||
        artefactsIsLoading ||
        usernamesIsLoading ||
        gameIsLoading ||
        isSubmitting ||
        isUpdating
    ) {
        return <Skeleton variant="rectangular" height={60} />;
    }

    const getPickAndBanStatus = (hero: Hero) => {
        if (hero.banned) return 'Banned';
        if (hero.dropped) return 'Dropped';
        return 'Picked';
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <SignedOut>
                <SignInButton afterSignInUrl="/games/editgame" mode="modal">
                    <Button color="primary" variant="contained">
                        Sign In
                    </Button>
                </SignInButton>
                <p>You must be signed in to submit game results.</p>
            </SignedOut>
            <SignedIn>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={4} md={3} lg={2}>
                        <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                            <DatePicker
                                label="Game Date"
                                value={selectedDate}
                                onChange={handleDateChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} sm={4} md={3} lg={2}>
                        <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                            <InputLabel id="gameFormat">Game Format</InputLabel>
                            <Select
                                labelId="selectGameFormatlabel"
                                id="selectGameFormat"
                                value={selectedGameFormat}
                                label="Game Format"
                                onChange={handleGameFormatChange}
                            >
                                <MenuItem value="Veto">Veto</MenuItem>
                                <MenuItem value="Immunity">Immunity</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} sm={4} md={3} lg={2}>
                        <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                            <InputLabel id="gameType">Game Type</InputLabel>
                            <Select
                                labelId="selectGameTypelabel"
                                id="selectGameType"
                                value={selectedGameType}
                                label="Game Type"
                                onChange={handleGameTypeChange}
                            >
                                <MenuItem value="Tabletop">Tabletop</MenuItem>
                                <MenuItem value="Vassal">Vassal</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} sm={4} md={3} lg={2}>
                        <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                            <InputLabel id="mapLabel">Map</InputLabel>
                            <Select
                                labelId="selectMaplabel"
                                id="selectMap"
                                value={selectedMap}
                                label="Map"
                                onChange={handleMapChange}
                            >
                                <MenuItem value="PleaseSelect">
                                    Please Select
                                </MenuItem>
                                <MenuItem value="Map1">Map 1</MenuItem>
                                <MenuItem value="Map2">Map 2</MenuItem>
                                <MenuItem value="Map3">Map 3</MenuItem>
                                <MenuItem value="Map4">Map 4</MenuItem>
                                <MenuItem value="Map5">Map 5</MenuItem>
                                <MenuItem value="Map6">Map 6</MenuItem>
                                <MenuItem value="Map7">Map 7</MenuItem>
                                <MenuItem value="Map8">Map 8</MenuItem>
                                <MenuItem value="Custom">Custom</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box
                    component="section"
                    sx={{ p: 2, border: '1px solid grey', borderRadius: 1 }}
                >
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <h3>Winning Warband</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="godLabel">God</InputLabel>
                                <Select
                                    labelId="winnerGodlabel"
                                    id="winnerGod"
                                    value={winnerGod}
                                    label="God"
                                    onChange={handleWinnerGodChange}
                                >
                                    <MenuItem value="Select God">
                                        Select God
                                    </MenuItem>
                                    {gods?.map((god) => (
                                        <MenuItem value={god.name}>
                                            {god.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={firstTurnWinner}
                                            onChange={
                                                handleFirstTurnWinnerChange
                                            }
                                            name="firstTurnWinner"
                                            color="primary"
                                        />
                                    }
                                    label="First Turn"
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <Autocomplete
                                    disablePortal
                                    value={winnerUsername}
                                    onChange={handleWinnerUsernameChange}
                                    options={
                                        users?.map(
                                            (clerkUser) => clerkUser.username
                                        ) || []
                                    }
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            label="Username"
                                        />
                                    )}
                                />
                                <FormHelperText>
                                    Select user that won the game
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <h4>Warband Heroes</h4>
                        </Grid>
                        {winnerHeroes.map((hero) => {
                            return (
                                <React.Fragment key={hero.name}>
                                    <Grid xs={12} sm={4} md={3} lg={2}>
                                        <img
                                            src={`/images/${hero.avatar_url}`}
                                            alt={hero.name}
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                display: 'block',
                                                margin: '0 auto',
                                            }}
                                        />
                                        <b
                                            style={{
                                                display: 'block',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {hero.name}
                                        </b>
                                    </Grid>
                                    <Grid xs={12} sm={4} md={3} lg={2}>
                                        <b>Off Artefact:</b>&nbsp;
                                        {hero.offensive_artefact}
                                    </Grid>
                                    <Grid xs={12} sm={4} md={3} lg={2}>
                                        <b>Def Artefact:</b>&nbsp;
                                        {hero.defensive_artefact}
                                    </Grid>
                                    <Grid xs={12} sm={4} md={3} lg={2}>
                                        <b>Level:</b>&nbsp;
                                        {hero.level}
                                    </Grid>
                                    <Grid xs={12} sm={4} md={3} lg={2}>
                                        {getPickAndBanStatus(hero)}
                                    </Grid>
                                    <Grid xs={12} sm={4} md={3} lg={2}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                removeWinnerHero(hero.name)
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="heroLabel">Hero</InputLabel>
                                <Select
                                    labelId="selectHerolabel"
                                    id="selectHero"
                                    value={selectedWinnerHero}
                                    label="Hero"
                                    onChange={handleWinnerHeroChange}
                                >
                                    <MenuItem value="Please Select">
                                        Please Select
                                    </MenuItem>
                                    {heroes
                                        ?.filter(
                                            (hero) => hero.name !== 'Sarna'
                                        )
                                        .map((hero) => (
                                            <MenuItem value={hero.name}>
                                                {hero.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="offensive_artefactLabel">
                                    Offensive Artefact
                                </InputLabel>
                                <Select
                                    labelId="selectOffensiveArtefactlabel"
                                    id="selectOffensiveArtefact"
                                    value={selectedWinnerHeroOffensiveArtefact}
                                    label="Offensive Artefact"
                                    onChange={
                                        handleWinnerHeroOffensiveArtefactChange
                                    }
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    {artefacts
                                        ?.filter(
                                            (artefact) =>
                                                artefact.type === 'Offensive'
                                        )
                                        .map((artefact) => (
                                            <MenuItem value={artefact.name}>
                                                {artefact.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="defensive_artefactLabel">
                                    Defensive Artefact
                                </InputLabel>
                                <Select
                                    labelId="selectDefensiveArtefactlabel"
                                    id="selectDefensiveArtefact"
                                    value={selectedWinnerHeroDefensiveArtefact}
                                    label="Defensive Artefact"
                                    onChange={
                                        handleWinnerHeroDefensiveArtefactChange
                                    }
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    {artefacts
                                        ?.filter(
                                            (artefact) =>
                                                artefact.type === 'Defensive'
                                        )
                                        .map((artefact) => (
                                            <MenuItem value={artefact.name}>
                                                {artefact.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="levelLabel">
                                    Hero Level
                                </InputLabel>
                                <Select
                                    labelId="selectHeroLevellabel"
                                    id="selectHeroLevel"
                                    value={selectedWinnerHeroLevel}
                                    label="Hero Level"
                                    onChange={handleWinnerHeroLevelChange}
                                >
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="3">3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="pickAndBanLabel">
                                    Pick & Ban
                                </InputLabel>
                                <Select
                                    labelId="selectPickAndBanlabel"
                                    id="selectPickAndBan"
                                    value={selectedWinnerHeroPickAndBan}
                                    label="Pick & Ban"
                                    onChange={handleWinnerHeroPickAndBanChange}
                                >
                                    <MenuItem value="Picked">Picked</MenuItem>
                                    <MenuItem value="Banned">Banned</MenuItem>
                                    <MenuItem value="Dropped">Dropped</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={addWinnerHero}
                            >
                                Add Hero
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    component="section"
                    sx={{ p: 2, border: '1px solid grey', borderRadius: 1 }}
                >
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <h3>Losing Warband</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="godLabel">God</InputLabel>
                                <Select
                                    labelId="loserGodlabel"
                                    id="loserGod"
                                    value={loserGod}
                                    label="God"
                                    onChange={handleLoserGodChange}
                                >
                                    <MenuItem value="Select God">
                                        Select God
                                    </MenuItem>
                                    {gods?.map((god) => (
                                        <MenuItem value={god.name}>
                                            {god.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="loserSoulsLabel">
                                    Loser Souls
                                </InputLabel>
                                <Select
                                    labelId="selectLoserSoulslabel"
                                    id="selectLoserSouls"
                                    value={loserSouls}
                                    label="Loser Souls"
                                    onChange={handleLoserSoulsChange}
                                >
                                    <MenuItem value="0">0</MenuItem>
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="3">3</MenuItem>
                                    <MenuItem value="4">4</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={firstTurnLoser}
                                            onChange={
                                                handleFirstTurnLoserChange
                                            }
                                            name="firstTurnLoser"
                                            color="primary"
                                        />
                                    }
                                    label="First Turn"
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <Autocomplete
                                    disablePortal
                                    value={loserUsername}
                                    onChange={handleLoserUsernameChange}
                                    options={
                                        users?.map(
                                            (clerkUser) => clerkUser.username
                                        ) || []
                                    }
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            label="Username"
                                        />
                                    )}
                                />
                                <FormHelperText>
                                    Select user that lost the game
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <h4>Warband Heroes</h4>
                        </Grid>
                        {loserHeroes.map((hero) => {
                            return (
                                <React.Fragment key={hero.name}>
                                    <Grid xs={12} sm={4} md={3} lg={2}>
                                        <img
                                            src={`/images/${hero.avatar_url}`}
                                            alt={hero.name}
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                display: 'block',
                                                margin: '0 auto',
                                            }}
                                        />
                                        <b
                                            style={{
                                                display: 'block',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {hero.name}
                                        </b>
                                    </Grid>
                                    <Grid xs={12} sm={4} md={3} lg={2}>
                                        <b>Off Artefact:</b>&nbsp;
                                        {hero.offensive_artefact}
                                    </Grid>
                                    <Grid xs={12} sm={4} md={3} lg={2}>
                                        <b>Def Artefact:</b>&nbsp;
                                        {hero.defensive_artefact}
                                    </Grid>
                                    <Grid xs={12} sm={4} md={3} lg={2}>
                                        <b>Level:</b>&nbsp;
                                        {hero.level}
                                    </Grid>
                                    <Grid xs={12} sm={4} md={3} lg={2}>
                                        {getPickAndBanStatus(hero)}
                                    </Grid>
                                    <Grid xs={12} sm={4} md={3} lg={2}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                removeLoserHero(hero.name)
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="heroLabel">Hero</InputLabel>
                                <Select
                                    labelId="selectHerolabel"
                                    id="selectHero"
                                    value={selectedLoserHero}
                                    label="Hero"
                                    onChange={handleLoserHeroChange}
                                >
                                    <MenuItem value="Please Select">
                                        Please Select
                                    </MenuItem>
                                    {heroes
                                        ?.filter(
                                            (hero) => hero.name !== 'Sarna'
                                        )
                                        .map((hero) => (
                                            <MenuItem value={hero.name}>
                                                {hero.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="offensive_artefactLabel">
                                    Offensive Artefact
                                </InputLabel>
                                <Select
                                    labelId="selectOffensiveArtefactlabel"
                                    id="selectOffensiveArtefact"
                                    value={selectedLoserHeroOffensiveArtefact}
                                    label="Offensive Artefact"
                                    onChange={
                                        handleLoserHeroOffensiveArtefactChange
                                    }
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    {artefacts
                                        ?.filter(
                                            (artefact) =>
                                                artefact.type === 'Offensive'
                                        )
                                        .map((artefact) => (
                                            <MenuItem value={artefact.name}>
                                                {artefact.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="defensive_artefactLabel">
                                    Defensive Artefact
                                </InputLabel>
                                <Select
                                    labelId="selectDefensiveArtefactlabel"
                                    id="selectDefensiveArtefact"
                                    value={selectedLoserHeroDefensiveArtefact}
                                    label="Defensive Artefact"
                                    onChange={
                                        handleLoserHeroDefensiveArtefactChange
                                    }
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    {artefacts
                                        ?.filter(
                                            (artefact) =>
                                                artefact.type === 'Defensive'
                                        )
                                        .map((artefact) => (
                                            <MenuItem value={artefact.name}>
                                                {artefact.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="levelLabel">
                                    Hero Level
                                </InputLabel>
                                <Select
                                    labelId="selectHeroLevellabel"
                                    id="selectHeroLevel"
                                    value={selectedLoserHeroLevel}
                                    label="Hero Level"
                                    onChange={handleLoserHeroLevelChange}
                                >
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="3">3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                                <InputLabel id="pickAndBanLabel">
                                    Pick & Ban
                                </InputLabel>
                                <Select
                                    labelId="selectPickAndBanlabel"
                                    id="selectPickAndBan"
                                    value={selectedLoserHeroPickAndBan}
                                    label="Pick & Ban"
                                    onChange={handleLoserHeroPickAndBanChange}
                                >
                                    <MenuItem value="Picked">Picked</MenuItem>
                                    <MenuItem value="Banned">Banned</MenuItem>
                                    <MenuItem value="Dropped">Dropped</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={4} md={3} lg={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={addLoserHero}
                            >
                                Add Hero
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={6000}
                            onClose={handleSnackbarClose}
                        >
                            <MuiAlert
                                onClose={(event) =>
                                    handleSnackbarClose(event, 'timeout')
                                }
                                severity="warning"
                                sx={{ width: '100%' }}
                            >
                                {snackBarMessage}
                            </MuiAlert>
                        </Snackbar>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit Game
                        </Button>
                    </Grid>
                </Grid>
            </SignedIn>
        </Box>
    );
}

export default EditGame;
