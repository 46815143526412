import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import '../../main.css';
import GameHeroes from './interfaces/GameHeroes';

interface GameHeroesCardProps {
    gameHero: GameHeroes;
}

function GameHeroCard({ gameHero }: GameHeroesCardProps) {
    return (
        <Card
            sx={{
                maxWidth: 230,
                minHeight: 415,
                margin: '0 auto',
                padding: '0.1em',
            }}
            className="darken-on-hover"
        >
            <RouterLink to={`/heroes/${gameHero.url_name}`}>
                <CardMedia
                    component="img"
                    image={`/images/${gameHero.avatar_url}`}
                    alt={gameHero.name}
                    sx={{
                        width: '100%',
                        height: 'auto',
                        padding: 2,
                    }}
                />
            </RouterLink>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {gameHero.name}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ whiteSpace: 'pre-line' }}
                >
                    {!gameHero.banned && !gameHero.dropped
                        ? `Level: ${gameHero.level}`
                        : ''}
                </Typography>
                <Typography
                    variant="body2"
                    color="red"
                    style={{ whiteSpace: 'pre-line' }}
                >
                    {gameHero.banned ? `BANNED` : ''}
                </Typography>
                <Typography
                    variant="body2"
                    color="red"
                    style={{ whiteSpace: 'pre-line' }}
                >
                    {gameHero.dropped ? `DROPPED` : ''}
                </Typography>
                {gameHero.offensive_artefact !== 'None' && (
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ whiteSpace: 'pre-line' }}
                    >
                        {`${gameHero.offensive_artefact}`}
                    </Typography>
                )}
                {gameHero.defensive_artefact !== 'None' && (
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ whiteSpace: 'pre-line' }}
                    >
                        {`${gameHero.defensive_artefact}`}
                    </Typography>
                )}
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    component={RouterLink}
                    to={`/heroes/${gameHero.url_name}`}
                >
                    View Rules
                </Button>
            </CardActions>
        </Card>
    );
}

export default GameHeroCard;
