import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Helper to clear auth token on 401 responses
const clearAuthToken = () => {
    localStorage.removeItem('clerk-auth-token');
};

// Create base query with auth handling
const baseQueryWithAuth = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
        headers.set('Content-Type', 'application/json');

        try {
            const token = localStorage.getItem('clerk-auth-token');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
        } catch (error) {
            clearAuthToken();
        }

        return headers;
    },
});

// Wrap base query with auth error handling
const baseQueryWithAuthAndReauth = async (
    args: any,
    api: any,
    extraOptions: any
) => {
    const result = await baseQueryWithAuth(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        clearAuthToken();
    }
    return result;
};

const judgementApi = createApi({
    reducerPath: 'judgementApi',
    baseQuery: baseQueryWithAuthAndReauth,
    endpoints: () => ({}),
});

export default judgementApi;
